var PulseSVG = function(element){ 
	this.arterysList = [];
	this.svgObj = element;
	this.updateModel = function(){ };
	this.loaded = function(){ };
};

PulseSVG.prototype.init = function(){
	this.svgObj.addEventListener("load", function() {
		this.svgDoc = this.svgObj.contentDocument;
		this.svg = this.svgDoc.getElementsByTagName('svg')[0];

		var arterys = this.svg.querySelectorAll("path[type]");
		for(let artery of arterys){
			var a = new Artery(this.svg, artery.id);
			a.init();
			a.inherit = function(e, order,side,state,positive){
				var inherit = this.getArteryByOrderAndSide(order,side,positive);
				for(let obj of inherit) {
					obj.set(state);
				}
			}.bind(this);
			a.onPositive = function(e){
				this.updateModel();
			}.bind(this);
			a.onNegative = function(e){
				this.updateModel();
			}.bind(this);
			this.arterysList.push(a);
		}
		this.loaded();
		this.isLoaded = true;
	}.bind(this));
};

PulseSVG.prototype.getArteryByOrderAndSide = function (order,side,positive){
	var found = this.arterysList.filter(function(element) {
		return element.side == side;
	});
	var filtered;
		if(positive){
		filtered = found.filter(function(element) {
			return order > element.order;
		});
		}else{
		filtered = found.filter(function(element) {
			return element.order > order;
		});
		}
	return filtered;
}