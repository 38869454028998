/////////////////////
var selectedInjury;
var slider;
var output;
var panel;

function changeDominance(type){
	var data;
	var path=window.location.pathname.split("/");
	var newURL = window.location.protocol + "//" + window.location.host + "/"+path[1];
	switch(type){
		case 0:
			data=newURL+"/images/derecha.svg";
			break;
		case 1:
			data=newURL+"/images/izquierda.svg";
			break;
		case 2:
			data=newURL+"/images/balanceada.svg";
			break;
	}
	document.getElementById('svg').data = data;
}

function setInputs(a,b){
	slider = a;
	output= b;
	slider.value = 0.5;
	output.value = slider.value;

	slider.oninput = function() {
	  	output.value = this.value  * 100 + ' %';
	    heart.setOcclusion(this.value, selectedInjury)
	}
	slider.onchange = function() {
	  	output.value = this.value * 100 + ' %';
	    heart.setOcclusion(this.value, selectedInjury)
	}
	output.oninput = function() {
		var min = 0;
		var max = 1;
		var value=this.value;
		if(value.match(/^(0(\.\d+)?|1(\.0+)?)$/)){
			if(parseFloat(value) < min || isNaN(parseFloat(value))) {
			value = min;
			this.value = value;
			slider.value = this.value;
		}
	    else if(parseFloat(value) > max) {
	    	value = max; 
	    	this.value = value;
	    	slider.value = this.value;
	    }
		}else{
			if(value!='0.'){
				this.value='';
				slider.value = 0;
			}
			
		}
	  	slider.value = this.value;
	    heart.setOcclusion(this.value, selectedInjury)
	}
}
function print(str){
	var textarea = document.getElementById('txt');
	textarea.append(str + "\n")
	textarea.scrollTop = textarea.scrollHeight;
}

/* METODO NUEVO COMENTAR EL "setLesiones()" QUE ESTÁ DEBAJO
function setLesiones(injuries) {
	for(var obj of injuries){
		var injury = parseJSONtoObj(obj);
		if (existsSegmentOnSVG([injury.startSegA, injury.startSegB, injury.endSegA, injury.endSegB])) {
			my.namespace.detectSegment(JSON.stringify(injury));
			var list = new Array(injury.startSegA, injury.startSegB, injury.endSegA, injury.endSegB);
			var aux = new Array();
			list.forEach(element => {
				if (element.includes('init') && !aux.includes(element.substring(4, element.length-1))){
					aux.push(element.substring(4, element.length-1));
				}else if (element.includes('end') && !aux.includes(element.substring(3, element.length-1))){
					aux.push(element.substring(3, element.length-1));
				}
			});
			if (aux.length>1){
				var pid = injury.pid;
				aux.forEach(element => {
					var myInjury = {
						endPosA: 0,
						endPosB: 0,
						endSegA: "end"+element+"A",
						endSegB: "end"+element+"B",
						obs: injury.obs,
						pid: pid,
						startPosA: 0,
						startPosB: 0,
						startSegA: "init"+element+"A",
						startSegB: "init"+element+"B",
						vessel: "undefined"
					}
					pid++;
					
					printInjury(myInjury);
					heart.sequence=pid+1;
				});
			}else{
				printInjury(injury);
				
			heart.sequence=injury.pid+1;
			}
		}
	}
}
*/

function setLesiones(injuries) {
	const pids = [];
	for(var obj of injuries){
		var injury = parseJSONtoObj(obj);
		pids.push(injury.pid);
		if (existsSegmentOnSVG([injury.startSegA, injury.startSegB, injury.endSegA, injury.endSegB])) {
			printInjury(injury);
		}
	}
	if(pids.length) setSequence(Math.max(...pids));
	setTimeout(this.createListenerForRows(), 200);
}

function existsSegmentOnSVG(segmentsPoints) {
	let result = true;
	for (const iterator of segmentsPoints) {
		const array = Array.from(heart.segmentsList)
		if(!array.find((obj)=> iterator === obj.id)) {
			result = false;
		}
	}
	return result;
}
function parseJSONtoObj(lesion){
	var injury = new Injury();
	injury.pid = lesion.pid;
    injury.obs = lesion.obs;
    injury.startPosA=lesion.startPosA;
    injury.startPosB=lesion.startPosB;
    injury.endPosA=lesion.endPosA;
    injury.endPosB=lesion.endPosB;
    injury.startSegA=lesion.startSegA;
    injury.startSegB=lesion.startSegB;
    injury.endSegA=lesion.endSegA;
    injury.endSegB=lesion.endSegB;
    injury.vessel= lesion.vessel;
    injury.saved=lesion.saved;
    return injury;
}
function printInjury(injury){
	var selection = heart.newSelection = {
			id: injury.pid,
			pathPoints: heart.pathPoints.path[injury.vessel],
			path1: new SVGPathManager(heart.svg,"path1" + heart.injuryIdPrefix + injury.pid , "red", 0.5,"none",browser),
			path2: new SVGPathManager(heart.svg,"path2" +  heart.injuryIdPrefix + injury.pid , "green", 0.5,"none",browser),
		    vessel: injury.vessel,
		    saved:true
		};
	var vessel=locateVessel("end"+injury.startSegA.substring(4));
	var points=heart.pathPoints.path[vessel].points;
	var startPointA = getPoint(injury.startPosA,equivalentInPoints("end"+injury.startSegA.substring(4),vessel),false,equivalentInPoints(injury.startSegA,vessel));
	var endPointA = getPoint(injury.endPosA,equivalentInPoints(injury.endSegA,vessel),true,equivalentInPoints("init"+injury.endSegA.substring(3),vessel));
	var startPointB = getPoint(injury.startPosB,equivalentInPoints("end"+injury.startSegB.substring(4),vessel),false,equivalentInPoints(injury.startSegB,vessel));
	var endPointB = getPoint(injury.endPosB,equivalentInPoints(injury.endSegB,vessel),true,equivalentInPoints("init"+injury.endSegB.substring(3),vessel));
	var direction1=getDirection(startPointA, endPointA);
	var direction2=getDirection(startPointB, endPointB);
	var path1Points = [];
	var path2Points = [];
	// for(var obj in points){
	// 	if(direction1==1){
	// 		if(obj >= startPointA && obj <= endPointA){
	// 			path1Points.push(points[obj]);
	// 		} else if(obj >= endPointB && obj <= startPointB){
	// 			path2Points.push(points[obj]);
	// 		} 
	// 	}else{
	// 		if(obj >= endPointA && obj <= startPointA){
	// 			path1Points.push(points[obj]);
	// 		} else if(obj >= startPointB && obj <= endPointB){
	// 			path2Points.push(points[obj]);
	// 		}
	// 	}
	// }
	if(direction1==1){
		path1Points = points.filter(obj => obj.idx >= startPointA && obj.idx <= endPointA);
	} else {
		path1Points = points.filter(obj => obj.idx >= endPointA && obj.idx <= startPointA);
	}
	if(direction2==1){
		path2Points = points.filter(obj => obj.idx >= startPointB && obj.idx <= endPointB);
	} else {
		path2Points = points.filter(obj => obj.idx >= endPointB && obj.idx <= startPointB);
	}
	selection.occlusion=injury.obs;
	selection.path1.direction=direction1;
	selection.path1.fillColor=heart.fillColor;
	selection.path1.strokeColor=heart.borderColor;
	selection.path1.points=path1Points;
	selection.path2.direction=direction2;
	selection.path2.fillColor=heart.fillColor;
	selection.path2.strokeColor=heart.borderColor;
	selection.path2.points=path2Points;
	if(direction1==1){
		selection.path1.startPoint=path1Points[0];
	}else{
		selection.path1.startPoint=path1Points[path1Points.length-1];
	}
	if(direction2==1){
		selection.path2.startPoint=path2Points[0];
	}else{
		selection.path2.startPoint=path2Points[path2Points.length-1];
	}
	heart.injuries.push(selection);
	if (selection.path1.points.length > 0 && selection.path2.points.length > 0) {
		heart.setOcclusion(injury.obs,selection);
	} else {
		console.log('Bifurcción o fin SVG');
		console.log(selection);
	}
	
	heart.newSelection = null;
}
function getDirection(start, end){
	if(start < end){
		return 1;
	}else{
		return -1;
	}
}
function setSequence(pid){
	heart.sequence=pid+1;
}
function updateSaved(pid){
	var last=heart.injuries[heart.injuries.length-1];
	if(last.saved==false){
		heart.injuries[heart.injuries.length-1].id=pid;
		heart.injuries[heart.injuries.length-1].saved=true;
	}
}
function highlightFromInterface(pid){
	var injury = heart.getInjury(pid);
	if(injury) highlightInjury(heart.getInjury(pid),heart.highlightColor,heart.highlightColor)
}
function unHighlightFromInterface(pid){
	var injury = heart.getInjury(pid);
	if(injury) highlightInjury(injury,HeartSVG.getBorderColor(injury.occlusion),HeartSVG.getOcclusionColor(injury.occlusion))
}
function deleteInjurybyPid(pid){
	if(heart.getInjury(pid)) {
		deleteInjury(heart.getInjury(pid));
	}
}
function getPoint(percent,end,isEnd,start){
	var total=Math.abs(end.idx-start.idx);
	var idx;
	if(percent!=0){
		idx=(percent*total)/100;
	}else{
		if(isEnd){
			return end.idx;
		}else{
			return start.idx;
		}
	}
	if(isEnd){
		if(end.idx>start.idx){
			return end.idx-Math.round(idx);
		}else{
			return end.idx+Math.round(idx);
		}
	}else{
		if(end.idx>start.idx){
			return start.idx+Math.round(idx);
		}else{
			return start.idx-Math.round(idx);
		}
	}
}

function highlightInjury(injury,stroke,fill){
	var path1 = heart.svg.getElementById(injury.path1.id);
	var path2 = heart.svg.getElementById(injury.path2.id);
	path1.style.fill = fill;
	path2.style.fill = fill;
	path1.style.stroke = stroke;
	path2.style.stroke = stroke;
}
function removeInjury(injury){
	if(injury.saved){
		my.namespace.deleteInjury(injury.id)
	}else{
		deleteInjury(injury);
		
	}
}
function deleteInjury(injury){
	HeartSVG.removePaths(injury);
	heart.injuries.splice(heart.injuries.indexOf(injury),1);
}
function searchInjuries(){
	var ul=document.getElementById("deleteInjuryUL");
	var ul2=document.getElementById("oclusionInjuryUL");
	var ul3=document.getElementById("stentInjuryUL");

	var items = ul.getElementsByTagName('li');
	for (var i = items.length - 1; i >= 0; --i) {
		ul.removeChild(items[i]);
	}
	var items2 = ul2.getElementsByTagName('li');
	for (var i = items2.length - 1; i >= 0; --i) {
		ul2.removeChild(items2[i]);
	}
	var items3 = ul3.getElementsByTagName('li');
	for (var i = items3.length - 1; i >= 0; --i) {
		ul3.removeChild(items3[i]);
	}
	for (var i in heart.injuries) {
	    var li = document.createElement('li');
	    var li2 = document.createElement('li');
	    var li3 = document.createElement('li');
	    var id = heart.injuries[i].id;
	    li.id = "liDel"+id;
        li.innerHTML = "Lesion "+id;
        li2.id = "liOcl"+id;
        li2.innerHTML = "Lesion "+id;
        li3.id = "liStent"+id;
        li3.innerHTML = "Lesion "+id;
        ul.appendChild(li);
        ul2.appendChild(li2);
        ul3.appendChild(li3);
        $("#liDel"+id).hover(function(){
        	highlightInjury(getInjury(this),heart.highlightColor,heart.highlightColor);
        }
        , function(){
        	highlightInjury(getInjury(this),heart.borderColor,heart.fillColor);
        });
    	$("#liOcl"+id).hover(function(){
        	highlightInjury(getInjury(this),heart.highlightColor,heart.highlightColor);}
        , function(){
        	highlightInjury(getInjury(this),heart.borderColor,heart.fillColor);
    	});
    	$("#liStent"+id).hover(function(){
        	highlightInjury(getInjury(this),heart.highlightColor,heart.highlightColor);}
        , function(){
        	highlightInjury(getInjury(this),heart.borderColor,heart.fillColor);
    	});
    	$("#liDel"+id).click(function(){
        	removeInjury(getInjury(this));
        	this.remove();
        });
        $("#liOcl"+id).click(function(){
        	showDialogOclusion(getInjury(this));
        });
        $("#liStent"+id).click(function(){
        	addStent(getInjury(this))
        });
        
	}
}
function getInjury(target){
	var id = target.innerHTML.substring(7);
	for (var i in heart.injuries){
		if(heart.injuries[i].id == id){
			id = i;
		}
	}
	return heart.injuries[id];
}
function updateDlgEdit(pid){
	var injury = heart.getInjury(pid);
	lastOcclusion = injury.occlusion;
	this.selectedInjury = injury;
	objToPersist = injury;
	slider.value = injury.occlusion;
	output.value = injury.occlusion;

}
function showDialogOclusion(injury){
	 contextMenu.style.display = 'none';
	 injurySelected = false;
	 lastOcclusion = injury.occlusion;
	 dialog.style.display = 'block';
	 objToPersist = injury;
	 this.selectedInjury = injury;
	 slider.value = injury.occlusion;
	 output.value = injury.occlusion * 100 + ' %';
}
function closeDialog() {
	dialog.style.display = 'none';
	lastOcclusion = null;
	objToPersist = null;
    selectedInjury = null;
    slider.value = 0.5;
    output.value = 0.5;
    //clearDialog();
}
function checkSaved(){
	if(typeof selectedInjury != "undefined"){
		if(selectedInjury.saved){
			heart.setOcclusion(lastOcclusion,selectedInjury);
		}else{
			removeInjury(selectedInjury);
		}
	}
}
function showPanel(e,injury){
	var coordinates = relativePositionToSvg(e);
	
	panel.style.left = coordinates.x + 20 + 'px';
	panel.style.top = coordinates.y + 20 + 'px';
	panel.style.display = 'block';
	document.getElementById("oclusionLbl").innerHTML = (injury.occlusion*100).toFixed() + "%";
}
function closePanel() {
    panel.style.display = 'none';
	oclusionLbl.value = null;
}
function openContextMenu(e) {
	var coordinates = relativePositionToSvg(e);
		
	contextMenu.style.display = 'block';
	contextMenu.style.left = coordinates.x + 'px';
	contextMenu.style.top = coordinates.y + 'px';
}
function closeContextMenu() {
	contextMenu.style.display = 'none';
}

function addStent(injury){
	closeContextMenu();
	linkInjurytoStent(injury.id);
}

function addBalloon(injury){
	closeContextMenu();
	linkInjurytoBalloon(injury.id);
}

function relativePositionToSvg(e){
	//NO VALIDO PARA ANGULAR
	// var obj = document.getElementById('svg').getBoundingClientRect();
	// var doc = document.documentElement;
	// var left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
	// var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
	// return {
	// 	x: e.clientX + obj.left + left,
	// 	y: e.clientY + obj.top + top
	// 	};
	return {
	 	x: e.clientX ,
		y: e.clientY 
		};
}
function createInjury(){
	var injury = new Injury();
	if(objToPersist.saved==false){
		injury.obs = Number(objToPersist.occlusion);
	    var segment = heart.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,objToPersist.vessel)].getSegment(heart.segmentsList,heart.svg.getElementById(objToPersist.vessel)
			,objToPersist.path1.startPoint,objToPersist.path2.startPoint,objToPersist.path1.getFinalPoint(),objToPersist.path2.getFinalPoint());
	    var startPointA=objToPersist.path1.getStartPoint(segment.segmentBreakPoints,segment.segments,objToPersist.path1.startPoint);
	    var startPointB=objToPersist.path2.getStartPoint(segment.segmentBreakPoints,segment.segments,objToPersist.path2.startPoint);
	    var endPointA=objToPersist.path1.getEndPoint(segment.segmentBreakPoints,segment.segments,objToPersist.path1.getFinalPoint(),startPointA.type);
	    var endPointB=objToPersist.path2.getEndPoint(segment.segmentBreakPoints,segment.segments,objToPersist.path2.getFinalPoint(),startPointB.type);
	    injury.startPosA=Number(startPointA.percent);
	    injury.startPosB=Number(startPointB.percent);
	    injury.endPosA=Number(endPointA.percent);
	    injury.endPosB=Number(endPointB.percent);
	    injury.startSegA=startPointA.segment;
	    injury.startSegB=startPointB.segment;
	    injury.endSegA=endPointA.segment;
	    injury.endSegB=endPointB.segment;
	    injury.vessel= objToPersist.vessel;
	    injury.saved=false;
	}else{
		injury = objToPersist;
	}
	my.namespace.saveInjury({injury: JSON.stringify(injury), sequencePid: objToPersist.id});
}

function createListenerForRows(args){
	//Poner aquí los li de la tabla de lesiones cuando esté imprmentada
	var table = document.getElementsByClassName("injuries-table")[0];
	if(args!=null){
		setSequence(args.pid);
		updateSaved(args.last);
	}
	// Crea los listeners para destacar el li sobre el que estamos haciendo hover
	if(table.getElementsByTagName('tr')){
		for(var obj of table.getElementsByTagName('tr')){
			if(obj.dataset && obj.dataset['value']) {
				const value = JSON.parse(obj.dataset['value']);
				const pid = value.pid;
				obj.onmouseover= function(){
					highlightFromInterface(pid);
				};
				obj.onmouseout= function(){
					unHighlightFromInterface(pid);
				};
			}
		}
	}
}
function addListenersToInjury(){
	var data = document.getElementById('dlgCreationStentForm:creationFormStent:frmEInjuryStent:autocomplete_panel');
	if (data != undefined)injuryListeners(data.childNodes[0]);
	var data2 = document.getElementById('dlgCreationForm:creationFormBalloon:frmEInjuryBall:autocomplete_panel');
	if (data2 != undefined)injuryListeners(data2.childNodes[0]);
}
function injuryListeners(elements){
	if(elements != undefined){
		for(var obj in elements.childNodes){
			elements.childNodes[obj].onmouseover= function(){
				highlightFromInterface(this.dataset.itemValue)
			};
			elements.childNodes[obj].onmouseout= function(){
				unHighlightFromInterface(this.dataset.itemValue)
			};
		}
	}
}
function detectBrowser(){
	var result;
	if((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0){
		result="opera";
	}else if(typeof InstallTrigger !== 'undefined'){
		result="firefox";
	}else if(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))){
		result="safari";
	}else if(/*@cc_on!@*/false || !!document.documentMode){
		var isIE = true;
		result="explorer";
	}else if(!isIE && !!window.StyleMedia){
		result="edge";
	}else if(!!window.chrome){
		result="chrome";
	}
	return result;
}
function active_inactiveHeart(active){
	(active)?heart.svg.querySelector('[id^="viewport"]').style.pointerEvents = "": heart.svg.querySelector('[id^="viewport"]').style.pointerEvents = "none";
	// (bool)?heart.svg.setAttribute('style', 'pointer-events:none;'):heart.svg.setAttribute('style', '');
}
///////////////////////////
var heart;
var contextMenu;
var injurySelected = false;
var objToPersist;
var browser;
var lastOcclusion;
var dialog;
var slider;
var output;
function loadSVG () { 
	contextMenu = document.getElementById('contextMenu');
	panel = document.getElementById("panel");
	dialog = document.getElementsByTagName("dialog")[0];
	slider = document.getElementById("obs");
	output = document.getElementById("obsValue");
	browser = detectBrowser();
	setInputs(slider,output);
	heart = new HeartSVG("svg",browser);
	heart.init();
	heart.onPathClick = function(e, path){  }
	heart.onPathDblclick = function(e, path){}
	heart.onSVGMouseMove = function(e){}
	heart.onSVGMouseDown = function(e){}
	heart.onSVGMouseUp = function(e){}
	heart.onPathMouseMove = function(e, path){}
	heart.onPathMouseDown = function(e, path){}
    heart.onPathMouseOut = function(e, path){}
    heart.onPathSelected = function(selection){ // <----- ESTE 1
		var occlusion = document.getElementById("obs").value
        this.setOcclusion(occlusion, selection)
        showDialogOclusion(selection);
        //this.setOcclusion(0.5, selection)
    }
    heart.onInjuryCreated = function(selection){ // <----- ESTE 2
    	var segment = heart.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,selection.vessel)].getSegment(
						heart.segmentsList,
						heart.svg.getElementById(selection.vessel),
						selection.path1.startPoint,selection.path2.startPoint,selection.path1.getFinalPoint(),
						selection.path2.getFinalPoint());
		
		// TODO: QUITAR
		const svg = heart.svg;
		const slist = svg.querySelectorAll('[id^="init"],[id^="end"]');
		for(var i = 0; i < slist.length; ++i){
			var circle = slist[i];
			circle.classList.remove('selected');
		}
		for(var j = 0; j < segment.segments.length; ++j){
			var c = svg.querySelectorAll('[id^="init' + segment.segments[j] + '"]');
			c[0].classList.add('selected');
			c = svg.querySelectorAll('[id^="end' + segment.segments[j] + '"]');
			c[0].classList.add('selected');
		}
		// /TODO: QUITAR
		my.namespace.detectSegment(segment.segments);
		selectedInjury=selection;
    };
    
    heart.onInjuryEdited = function(injury){ // <----- ESTE 2
        selectedInjury=injury;
    };
	
	heart.onSVGClick = function(e){
		//closeContextMenu();
		injurySelected = false;
		closePanel();
		closeContextMenu();
	};
	
	heart.onSVGRightClick = function(e){
		//openContextMenu(e);
		//searchInjuries();
	};

	 heart.onInjurySelected = function(e, injury){
	  	highlightInjury(injury,heart.highlightColor,heart.highlightColor)
	 // 	var data = document.getElementById("formDynamicProcedure:mainTabPanel:tabPanel2:injuriesComp:dynamicInjuriesTable:injuriesTable_data");
		// var rows = data.childNodes;
		// for (var i = rows.length - 1; i >= 0; i--) {
		// 	(rows[i].childNodes[0].innerHTML==injury.pid)? rows[i].className+= " " + "ui-state-hover": null;
		// }
	 	showPanel(e,injury);
	 };

	 heart.onInjuryDeselected = function(e, injury){
		 highlightInjury(injury,HeartSVG.getBorderColor(injury.occlusion),HeartSVG.getOcclusionColor(injury.occlusion))
		// var data = document.getElementById("formDynamicProcedure:mainTabPanel:tabPanel2:injuriesComp:dynamicInjuriesTable:injuriesTable_data");
		// var rows = data.childNodes;
		// for (var i = rows.length - 1; i >= 0; i--) {
		// 	(rows[i].childNodes[0].innerHTML==injury.pid)? rows[i].classList.remove("ui-state-hover"): null;
		// }
	 	closePanel();
	 };

	 heart.onInjuryRightClick = function(e, selection){
		openContextMenu(e);
		injurySelected = true;
		selectedInjury = selection;
	 };
	 heart.onSVGLoaded= function(){
		checkPoints();
		my.namespace.getInjuries();
	 }

	$("#deleteInjury").hover(function(){
		if(!injurySelected){
			var ul=document.getElementById("deleteInjuryUL");
			ul.style.display = 'block';
		}else{
			this.onclick = function(){
				if(injurySelected)removeInjury(selectedInjury);
				closeContextMenu();
				closePanel();
			}
		}
	}, function(){
   		var ul=document.getElementById("deleteInjuryUL");
		ul.style.display = 'none';
    });
	$("#oclusionInjury").hover(function(){
		if(!injurySelected){
			var ul=document.getElementById("oclusionInjuryUL");
			ul.style.display = 'block';
		}else{
			this.onclick = function(){
				if(injurySelected)showDialogOclusion(selectedInjury);
				closeContextMenu();
			}
		}
	}, function(){
   		var ul=document.getElementById("oclusionInjuryUL");
		ul.style.display = 'none';
    });
    $("#stentInjury").hover(function(){
		if(!injurySelected){
			var ul=document.getElementById("stentInjuryUL");
			ul.style.display = 'block';
		}else{
			this.onclick = function(){
				if(injurySelected)addStent(selectedInjury);
				closeContextMenu();
			}
		}
	}, function(){
   		var ul=document.getElementById("stentInjuryUL");
		ul.style.display = 'none';
    });
    $("#balloonInjury").hover(function(){
    	if(!injurySelected){
			var ul=document.getElementById("balloonInjuryUL");
			ul.style.display = 'block';
		}else{
			this.onclick = function(){
				if(injurySelected)addBalloon(selectedInjury);
				closeContextMenu();
			}
		}
    },function(){
    	var ul=document.getElementById("balloonInjuryUL");
		ul.style.display = 'none';
	});
}
function onWindowClick(){
	injurySelected = false;
	closePanel();
	closeContextMenu();
 }
function equivalentInPoints(id,idPath){
	var circle = heart.svg.getElementById(id);
	var point;
	switch(browser){
		case "chrome":
		case "opera":
			 point = heart.pathPoints.path[idPath].findPointByCordinates(circle.getAttribute("cx"),circle.getAttribute("cy"));
			break;
		case "firefox":
		case "safari":
		case "explorer":
		case "edge":
			 point = heart.pathPoints.path[idPath].findPointByCordinates(circle.cx.baseVal.value,circle.cy.baseVal.value);
			break;
	}
	return point;
}
function locateVessel(id){
	var vessel;
	for(var i in heart.pathPoints.path){
		if(equivalentInPoints(id,i)!== undefined){
			vessel=i;
		}
	}
	return vessel;
}

function zoomAtPoint(sector){
	heart.zoomUtils.resetZoom();
	heart.zoomUtils.zoomAtPoint(3, {x: heart.svgObj.clientWidth/2, y: ((heart.svgObj.clientHeight/3)*sector)/2})
}

function checkPoints() {
	for (const iterator in heart.pathPoints.path) {
		for (const iterator2 of heart.segmentsList) {
			if (equivalentInPoints(iterator2.getAttribute("id"), iterator) == undefined) {
				console.log(iterator2.getAttribute("id") + ' no encontrado')
			}
		}
	} 
}

/////////////////////

function loadPulses(id){
	var pulses = new PulseSVG(id);
	pulses.init();
	return pulses;
}