var Injury = function(){
    this.startPosA;
    this.startPosB;
    this.endPosA;
    this.endPosB;
    this.startSegA;
    this.startSegB;
    this.endSegA;
    this.endSegB;
    this.obs;
    this.vessel;
    this.saved;
};