var HeartSVG = function(id,browser){
	this.id = id;
	this.pathPoints;
	this.zoomUtils;
	
	this.pathIdPrefix = "path";
	this.injuryIdPrefix = '__auxInjury';
	this.circleSize = 2;
	this.sequence=0;
	this.injuries;
	this.browser=browser;
	this.highlightColor="#1f22bf";
	this.borderColor="orange";
	this.fillColor="lightyellow";
	
	this.onSVGLoaded = function(){};
	this.onPathClick = function(e, path){};
	this.onPathDblclick = function(e, path){};
	this.onPathMouseMove = function(e, path){};
	this.onPathMouseDown = function(e, path){};
	this.onPathMouseOut = function(e, path){};
	this.onSVGMouseMove = function(e){};
	this.onSVGMouseUp = function(e){};
	this.onSVGMouseDown = function(e){};
	this.onSVGClick = function(e){};
	this.onSVGRightClick = function(e){};
	// al mover rapido no respeta que first debe e estar en un extremo
    this.onPathSelected = function(selection){};
    this.onInjuryCreated = function(injury){};
    this.onInjurySelected = function(e, selection){};
    this.onInjuryDeselected = function(e, selection){};
    this.onInjuryRightClick = function(e, selection){};
    this.onInjuryEdited = function(injury){};
};

HeartSVG.prototype.init = function(){
	this.svgObj = document.getElementById(this.id);
	this.svgObj.addEventListener("load", function() {
		  this.svgDoc = this.svgObj.contentDocument;
      	this.svg = this.svgDoc.getElementsByTagName('svg')[0];
      	this.zoomUtils = svgPanZoom('#svg', {
          zoomEnabled: true,
          controlIconsEnabled: true,
		  panEnabled: true,
		  maxZoom: 100
		});
		//this.zoomUtils.zoomEnabled = false;
		//console.log(this.zoomUtils)
		//this.zoomUtils.disableControlIcons();
		//x:255 mitad de el eje x
		//y:0 superior,150 medio, 300 inferior
		// panZoomTiger.zoomAtPoint(4, {x: 255, y:0})
		this.viewBox = this.svg.viewBox.baseVal;
		this.segmentsList = this.svg.querySelectorAll('[id^="init"],[id^="end"]');
		this.pathPoints = {path: new Array()};
		this.injuries = new Array();
		this.svg.viewportG = this.svg.querySelector('[id^="viewport"]');
		this.svgDoc.addEventListener("mousemove", function(e){
			this.captureMovement(e);
			this.onSVGMouseMove(e);
		}.bind(this), false);
		
		this.svgDoc.addEventListener("mousedown", function(e){
			this.onSVGMouseDown(e)
			e.stopPropagation();
		}.bind(this), false);
		
		this.svgDoc.addEventListener("mouseup", function(e) {
			if(this.newSelection!=null){
				if(this.newSelection.path1.direction!=0||this.newSelection.path2.direction!=0){
					this.captureMovement(e);
					this.setSelectedPath(this.newSelection);
					this.injuries.push(this.newSelection);
				}else{
					this.regenerateSequence(this.newSelection.id);
					HeartSVG.removePaths(this.newSelection);
				}
			}
			this.newSelection = null;
            this.onSVGMouseUp(e)
		}.bind(this), false);
		this.svgDoc.addEventListener("click", function(e){
			this.onSVGClick(e);
		}.bind(this), false);
		this.svgDoc.addEventListener("contextmenu", function(e) {
			e.preventDefault();
			this.onSVGRightClick(e);
			return false;
		}.bind(this), false);
		var i = 0;	
		while(this.initPath(i++)) {}
		this.onSVGLoaded();
 	}.bind(this), false)
};

HeartSVG.prototype.setOcclusion = function (occlusion, selection) {
	var obs_2 = occlusion/2;

    selection.path1.deleteCalculated();
    selection.path2.deleteCalculated();
    
    SVGPathManager.matchPoints(selection.path1, selection.path2);

    selection.path1.calculatePathWidth(obs_2);
    selection.path2.calculatePathWidth(obs_2);

    selection.path1.strokeColor = selection.path2.strokeColor = HeartSVG.getBorderColor(occlusion);
    selection.path1.strokeWidth = selection.path2.strokeWidth = 0.1;
    selection.path1.fillColor = selection.path2.fillColor = HeartSVG.getOcclusionColor(occlusion);
    selection.occlusion = occlusion;

	HeartSVG.redrawPaths(selection);
	this.addListenerstoInjury(selection);
	
	if(selection.saved==false){
	    this.onInjuryCreated(selection)
	}else{
		var injury = this.getInjury(selection.id);
		injury.pid=selection.id;
		injury.obs = occlusion;
		this.onInjuryEdited(injury)
	}
};

HeartSVG.prototype.getInjury = function(id){
	if (Array.prototype.find) {
		return this.injuries.find(function (obj) { return obj.id == id; });
	}else{
		for (var i = this.injuries.length - 1; i >= 0; i--) {
			if(this.injuries[i].id==id){
				return this.injuries[i];
			}
		}
	}
};

HeartSVG.prototype.continuePath = function (mouse, path) {
    var closest = HeartSVG.getClosestPointLinkedTo(mouse, this.newSelection.pathPoints, path);
    this.addOrRemovePoint(mouse, closest, path);
};

HeartSVG.prototype.captureMovement = function (e) {
    if(this.newSelection != null){
		var mouse = this.getRelativePosition(e);
		var path = this.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,e.target.id)];
		if(!path){
			path = this.lastPath;
		}
		this.lastPath = path;
		var points = HeartSVG.getClosestNotConsecuivePoints(mouse, path);
		var d0_1 = HeartSVG.getDistanceTo(points.p0, this.newSelection.path1);
		var d0_2 = HeartSVG.getDistanceTo(points.p0, this.newSelection.path2);
		var d1_1 = HeartSVG.getDistanceTo(points.p1, this.newSelection.path1);
		var d1_2 = HeartSVG.getDistanceTo(points.p1, this.newSelection.path2);
		//first candidates round
		if (d0_1 < d0_2 && // p0 is closer to path1
			d1_1 > d1_2) { // p1 is closer to path2
				// both distances agree, thus
				this.addOrRemovePoint(mouse, points.p0, this.newSelection.path1);
				this.addOrRemovePoint(mouse, points.p1, this.newSelection.path2);
		} else if (	d0_1 > d0_2 && // p0 is closer to path2
					d1_1 < d1_2) { // p1 is closer to path1
				// both distances agree, thus
				this.addOrRemovePoint(mouse, points.p0, this.newSelection.path2);
				this.addOrRemovePoint(mouse, points.p1, this.newSelection.path1);
		} else {
			// in this case, there is no agreement, the same point is closer to both paths than the other
			// The closest of the closest will win
			var minDist = Math.min(d0_1, d0_2, d1_1, d1_2);
			if(minDist === d0_2 || minDist === d1_1) {
				this.addOrRemovePoint(mouse, points.p0, this.newSelection.path2);
				this.addOrRemovePoint(mouse, points.p1, this.newSelection.path1);
			} else {
				this.addOrRemovePoint(mouse, points.p0, this.newSelection.path1);
				this.addOrRemovePoint(mouse, points.p1, this.newSelection.path2);
			}
		}
        HeartSVG.redrawPaths(this.newSelection);
    }
};

HeartSVG.prototype.addOrRemovePoint = function(mouse, closest, pathPoints){
	if(!closest || !pathPoints) {
		return;
	}
	// we are all set, the input values can be processed
	
	// If the point is not yet added to the path, we should add it
	if(!HeartSVG.pointInPath(closest, pathPoints)){
		// usually the next point will be close to the idx of the previous or the next one,
		// but it doesn't have to be allways that way, so the best approach is calculate the distance
		var dist2beg = Math.pow(pathPoints.getElement(0).x - closest.x, 2) + Math.pow(pathPoints.getElement(0).y - closest.y, 2)
		var dist2end = Math.pow(pathPoints.getElement(pathPoints.points.length - 1).x - closest.x, 2) + Math.pow(pathPoints.getElement(pathPoints.points.length - 1).y - closest.y, 2)
		
		if(dist2beg < dist2end) {
			pathPoints.preppend(closest, true);
		} else {
			pathPoints.append(closest, true);
		}
	} else if(pathPoints.size() > 1){ // there is no linked item to be added, shall we remove an item
		closest = HeartSVG.getClosestPoint(mouse, pathPoints);
		if(closest.startPoint){ // there should be just one element
			pathPoints.restart()
		}else{ // there are several elements
            if(pathPoints.size() > 2){
                var len = pathPoints.size();
                if(closest.idx == pathPoints.getElement(1).idx && !pathPoints.getElement(0).startPoint){
                    pathPoints.removeFirst()
                }else if (closest.idx == pathPoints.getElement(len-2).idx && !pathPoints.getElement(len-1).startPoint){
                    pathPoints.removeLast()
                }
            }
		}
	}
};

HeartSVG.prototype.setSelectedPath = function (selection) {
	if(selection) {
		this.lastSelection = selection;
		var path = selection.path1
		this.onPathSelected(selection);
    }
};

HeartSVG.getDistanceTo = function(point, path){
	var points = path.points;
	var dist = 99999;
	for(var i = 0; i < points.length; ++i) {
		var newDist = Math.abs(points[i].idx - point.idx)
		if(newDist < dist){
			dist = newDist;
		}
	}
	return dist;
}

HeartSVG.getClosestNotConsecuivePoints = function (mouse, pathPoints) {
    var points = {
        p0: HeartSVG.getClosestPoint(mouse, pathPoints),
        p1: null
    };
    points.p1 = HeartSVG.getClosestPointNotLinkedTo(mouse, pathPoints, points.p0);
    return points
};

HeartSVG.prototype.initPath = function(pathId){
	var found = false;
	var path = this.svg.getElementById(this.pathIdPrefix+pathId);
	if(path != null){
		found = true;
		//Para Explorer
		var manager =  new SVGPathManager(null,null,null,null,null,browser);
		manager.parse(path);
		this.pathPoints.path.splice(pathId,0,manager);
		path.addEventListener("mousedown", function(e){
            var mouse = this.getRelativePosition(e);
			this.newSelection = {
				id: this.sequence,
				pathPoints: this.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,e.target.id)],
				path1: new SVGPathManager(this.svg,"path1" + this.injuryIdPrefix + this.sequence , "red", 0.5,"none",browser),
				path2: new SVGPathManager(this.svg,"path2" +  this.injuryIdPrefix + this.sequence , "green", 0.5,"none",browser),
			    vessel: e.target.id,
			    saved:false
			};
			this.sequence++;

			var pair = HeartSVG.getClosestNotConsecuivePoints(mouse, this.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,e.target.id)]);
			this.newSelection.path1.configureStartPoint(pair.p0);
			this.newSelection.path2.configureStartPoint(pair.p1);

			HeartSVG.redrawPaths(this.newSelection);
			this.onPathMouseDown(e, path)
		}.bind(this), false);
		
		path.addEventListener("mousemove", function(e){
            var mouse = this.getRelativePosition(e);
            var pair = HeartSVG.getClosestNotConsecuivePoints(mouse, this.pathPoints.path[SVGManager.positionById(heart.pathIdPrefix,e.target.id)]);
			SVGManager.redrawCircle(this.svg, "c1", pair.p0.x, pair.p0.y, this.circleSize, "yellow", 0);
			SVGManager.redrawCircle(this.svg, "c2", pair.p1.x, pair.p1.y, this.circleSize, "orange", 0);
			this.onPathMouseMove(e, path)
		}.bind(this), false);
		
		path.addEventListener("mouseout", function(e){
			SVGManager.removeElement(this.svg, "c1");
			SVGManager.removeElement(this.svg, "c2");
			this.onPathMouseOut(e, path)
		}.bind(this), false);
		
		path.onclick = function(e){
			this.onPathClick(e, path);
		}.bind(this);
					
		path.ondblclick = function(e){
			this.onPathDblclick(e, path);
		}.bind(this)
	}
	return found;
};
HeartSVG.drawPaths = function(selection){
	selection.path1.draw();
	selection.path2.draw();
};

HeartSVG.redrawPaths = function(selection){
	selection.path1.redraw();
	selection.path2.redraw();
};
HeartSVG.removePaths = function(selection){
	selection.path1.remove();
	selection.path2.remove();
};
HeartSVG.prototype.regenerateSequence = function(id){
	this.sequence = id;
};
HeartSVG.prototype.addListenerstoInjury = function(selection){
	this.svg.getElementById(selection.path1.id).onmouseover = this.svg.getElementById(selection.path2.id).onmouseover = function(e){
		this.onInjurySelected(e, selection);
	}.bind(this);
	this.svg.getElementById(selection.path1.id).onmouseout = this.svg.getElementById(selection.path2.id).onmouseout = function(e){
		this.onInjuryDeselected(e, selection);
	}.bind(this);
	/* CHROME
	this.svg.getElementById(selection.path1.id).oncontextmenu = this.svg.getElementById(selection.path2.id).oncontextmenu = function(e){
		this.onInjuryRightClick(e, selection);
	}.bind(this);*/
	this.svg.getElementById(selection.path1.id).addEventListener("contextmenu", function(e) {
		this.onInjuryRightClick(e, selection);
	}.bind(this), false);
	this.svg.getElementById(selection.path2.id).addEventListener("contextmenu", function(e) {
		this.onInjuryRightClick(e, selection);
	}.bind(this), false);
}

HeartSVG.getClosestPoint = function(needle, array){
    var closest = null;
	var closestDistance = null;
	array.points.forEach(function(point) {
        if (point.type != 'Z') {
            var e;
            e = SVGManager.euclideanDistance(point, needle);
            if (e < closestDistance || closestDistance == null) {
                closestDistance = e;
                closest = point;
            }
        }
    });
	return closest;
};

HeartSVG.getClosestPointLinkedTo = function(needle, array, pathArray){
    var closestInPath = HeartSVG.getClosestPoint(needle, pathArray);

    if(closestInPath) {
        var prev = array.getElement((closestInPath.idx <= 0) ? array.size() - 1 : closestInPath.idx - 1);
        var post = array.getElement((closestInPath.idx >= array.size() - 1) ? 0 : closestInPath.idx + 1);

		if(prev && post) {
            var closestDistance = SVGManager.euclideanDistance(closestInPath, needle);
            var prevDistance = SVGManager.euclideanDistance(prev, needle);
            var postDistance = SVGManager.euclideanDistance(post, needle);
            if (prevDistance < postDistance && prevDistance < closestDistance) {
                return prev
            } else if (postDistance < prevDistance && postDistance < closestDistance) {
                return post
            }
        }
    }
};

HeartSVG.getClosestPointNotLinkedTo = function(needle, array, link){
    var minDistance =10;

    var closestPoint = null;
    var closestDistance = Infinity;
    array.points.forEach(function(point) {
        var pointDistance = Math.abs(point.idx - link.idx);
		if(minDistance < pointDistance){
			var e = SVGManager.euclideanDistance(point, needle);
			if(e < closestDistance){
				closestDistance = e;
				closestPoint = point;
			}
		}
	});
	return closestPoint;
};

HeartSVG.prototype.getRelativePosition = function(e){
	/*
	return {
		x: (e.clientX * this.viewBox.width / this.svgObj.offsetWidth) + this.viewBox.x,
		y: (e.clientY * this.viewBox.height / this.svgObj.offsetHeight) + this.viewBox.y
		};*/
	var matrix = this.svg.viewportG.getCTM();
	// transform a point using the transformed matrix
	var position = this.svg.createSVGPoint();
	position.x =  e.clientX;
	position.y = e.clientY;
	position = position.matrixTransform(matrix.inverse());
	return {
			x: position.x,
			y: position.y
			};
};

HeartSVG.pointInPath = function(needle, array){
	for(var i = 0; i < array.size(); ++i){
		if(needle.idx == array.getElement(i).idx){
			return true;
		}
	}
	return false;
};

HeartSVG.getOcclusionColor = function (occlusion){
	if(occlusion >= 0.7 && occlusion <= 1) return "#ff0028"; else if(occlusion >= 0.3 && occlusion < 0.7) return "#f3ed7b"; else return "#95b6e9";
};

HeartSVG.getBorderColor = function (occlusion){
	if(occlusion >= 0.7 && occlusion <= 1) return "#b3222d"; else if(occlusion >= 0.3 && occlusion < 0.7) return "#bda853"; else return "#4f86d9";
};