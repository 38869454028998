//////////////////////////////////

var Artery = function(svg, id){
	this.svg = svg;
	this.id = id;
	this.minus;
	this.plus;
	this.point;
	this.obj;
	this.order;
	this.side;
	this.state = state.DISABLED;

	this.onNegative = function(e){};
	this.onPositive = function(e){};
	this.inherit = function(e,limit,side,positive){};
};
const state = {
    DISABLED: 'disabled',
    POSITIVE: 'positive',
    NEGATIVE: 'negative'
}
Artery.prototype.init = function(){
	this.obj = this.svg.getElementById(this.id);
	this.order = this.obj.getAttribute("order");
	this.minus = this.svg.getElementById("minus_"+this.id);
	this.minus.style.cursor = "pointer";
	this.plus = this.svg.getElementById("plus_"+this.id);
	this.plus.style.cursor = "pointer";
	this.point = this.svg.getElementById(this.id+"_point");
	this.side = this.id.split("Artery")[1];

	this.minus.addEventListener("click", function(e){
		this.minusClick(e);
	}.bind(this), false);
		
	this.plus.addEventListener("click", function(e){
		this.plusClick(e);
	}.bind(this), false);
};

Artery.prototype.set = function(e) {
	this.paint(e);
	this.state = e;
}

Artery.prototype.convertToArteryState = function(e) {
	let result = state.DISABLED;
	switch(e) {
		case 0: result = state.POSITIVE;
		break;
		case 1: result = state.DISABLED;
		break;
		case 2: result = state.NEGATIVE;
		break;
	}
	return result;
}

Artery.prototype.minusClick = function(e){
	switch(this.state){
		case state.DISABLED:
			this.paint(state.NEGATIVE);
			this.state = state.NEGATIVE;
			this.inherit(e,this.order,this.side,this.state,false);
		break;
		case state.POSITIVE:
			this.paint(state.NEGATIVE);
			this.state = state.NEGATIVE;
			this.inherit(e,this.order,this.side,this.state,false);
		break;
		case state.NEGATIVE:
			this.paint(state.DISABLED);
			this.state = state.DISABLED;
			this.inherit(e,this.order,this.side,this.state,false);
		break;
	}
	this.onNegative(e);
}

Artery.prototype.plusClick = function(e){
	switch(this.state){
		case state.DISABLED:
			this.paint(state.POSITIVE)
			this.state = state.POSITIVE;
			this.inherit(e,this.order,this.side,this.state,true);
		break;
		case state.POSITIVE:
			this.paint(state.DISABLED)
			this.state = state.DISABLED;
			// this.inherit(e,this.order,this.side,this.state,true);
		break;
		case state.NEGATIVE:
			this.paint(state.POSITIVE)
			this.state = state.POSITIVE;
			this.inherit(e,this.order,this.side,this.state,true);
		break;
	}
	this.onPositive(e);
}

Artery.prototype.paint = function (newState){
	switch(newState){
		case state.DISABLED:
			switch(this.state){
				case state.POSITIVE:
					this.plus.classList.remove("positive");
					this.obj.classList.remove("positiveArtery");
					this.point.classList.remove("positivePoint");
				break;
				case state.NEGATIVE:
					this.minus.classList.remove("negative");
					this.obj.classList.remove("negativeArtery");
					this.point.classList.remove("negativePoint");
				break;
			}
		break;
		case state.POSITIVE:
			switch(this.state){
				case state.DISABLED:
					this.plus.classList.add("positive");
					this.obj.classList.add("positiveArtery");
					this.point.classList.add("positivePoint");
				break;
				case state.NEGATIVE:
					this.minus.classList.remove("negative");
					this.obj.classList.remove("negativeArtery");
					this.point.classList.remove("negativePoint");

					this.plus.classList.add("positive");
					this.obj.classList.add("positiveArtery");
					this.point.classList.add("positivePoint");
				break;
			}
		break;
		case state.NEGATIVE:
			switch(this.state){
				case state.DISABLED:
					this.minus.classList.add("negative");
					this.obj.classList.add("negativeArtery");
					this.point.classList.add("negativePoint");
				break;
				case state.POSITIVE:
					this.plus.classList.remove("positive");
					this.obj.classList.remove("positiveArtery");
					this.point.classList.remove("positivePoint");

					this.minus.classList.add("negative");
					this.obj.classList.add("negativeArtery");
					this.point.classList.add("negativePoint");
				break;
			}
		break;
	}
};