
var SVGPathManager = function (svg, id, strokeColor, strokeWidth, fillColor, browser) {
	this.points = [];
	this.direction = 0;
	this.startPoint = null;
	this.id = id;
	this.strokeColor = strokeColor;
	this.strokeWidth = strokeWidth;
	this.fillColor = (fillColor) ? fillColor : "none";
	this.svg = svg;
	this.browser = browser;
	this.proximity_threshold = 0.1;

};

SVGPathManager.prototype.toString = function () {
	var str = "";
	for (var i = 0; i < this.points.length; ++i) {
		var pathPoint = this.points[i];
		if (i == 0) {
			pathPoint = {
				type: "M",
				x: pathPoint.x,
				y: pathPoint.y
			}
		}
		str += SVGPathManager.pointToString(pathPoint) + " ";
	}

	return str
};

SVGPathManager.copy = function (old) {
	var obj = new SVGPathManager();
	for (var field in old) {
		if (old.hasOwnProperty(field)) {
			obj[field] = old[field];
		}
	}
	return obj;
};

SVGPathManager.pointToString = function (point) {
	if (point == null) {
		return '';
	}
	var str = point.type;
	switch (point.type) {
		case "M":
		case "L":
		case "T":
			str += " " + point.x + " " + point.y;
			break;
		case "C":
			str += " " + point.x1 + " " + point.y1 + " " + point.x2 + " " + point.y2 + " " + point.x + " " + point.y;
			break;
		case "A":
			str += " " + point.rx + " " + point.ry + " " + point.x_axis_rotation + " " + point.large_arc_flag + " " + point.sweep_flag + " " + point.x + " " + point.y;
			break;
		case "S":
			str += " " + point.x2 + " " + point.y2 + " " + point.x + " " + point.y;
			break;
		case "Q":
			str += " " + point.x1 + " " + point.y1 + " " + point.x + " " + point.y;
			break;
		case "H":
			str += " " + point.x;
			break;
		case "V":
			str += " " + point.y;
			break;
		case "Z":
		default: break;
	}
	return str;
};

SVGPathManager.prototype.parse = function (path) {
	var segments = this.getPathObj(path);
	this.points = [];
	var lastPoint = {};
	for (var j = 0, len = segments.length; j < len; ++j) {
		var pathSeg = segments[j];
		var values = pathSeg.values;
		var point = null;
		switch (pathSeg.type.toUpperCase()) {
			case "M":
				point = { x: values[0], y: values[1] };
				break;
			case "L":
				point = { x: values[0], y: values[1] };
				break;
			case "C":
				point = { x1: values[0], y1: values[1], x2: values[2], y2: values[3], x: values[4], y: values[5] };
				break;
			case "A":
				point = { rx: values[0], ry: values[1], x_axis_rotation: values[2], large_arc_flag: values[3], sweep_flag: values[4], x: values[5], y: values[6] };
				break;
			case "S":
				point = { x2: values[0], y2: values[1], x: values[2], y: values[3] };
				break;
			case "Q":
				point = { x1: values[0], y1: values[1], x: values[2], y: values[3] };
				break;
			case "T":
				point = { x: values[0], y: values[1] };
				break;
			case "Z":
				point = { x: values[0], y: values[1] };
				break;
			case "H":
				point = { x: values[0], y: lastPoint.y };
				break;
			case "V":
				point = { x: lastPoint.x, y: values[0] };
				break;
			default: break;
		}
		point.type = pathSeg.type;
		point.idx = j;
		this.points.push(point);
		lastPoint = point;
	}
};

SVGPathManager.prototype.configureStartPoint = function (point) {
	if (this.points.length == 0) {
		point = JSON.parse(JSON.stringify(point));
		point.startPoint = true;
		this.startPoint = point
	}
	this.points.push(point);
};

SVGPathManager.prototype.updateDirection = function (direction, restart) {
	if (restart && this.direction != direction) {
		this.restart();
		this.direction = direction
	}
};

SVGPathManager.prototype.append = function (point, restart) {
	this.updateDirection(1, restart);
	this.points.push(point);
};

SVGPathManager.prototype.preppend = function (point, restart) {
	this.updateDirection(-1, restart);
	this.points.unshift(point);
};

SVGPathManager.prototype.getElement = function (i) {
	return this.points[i];
};

SVGPathManager.prototype.size = function () {
	return this.points.length;
};

SVGPathManager.prototype.removeElement = function (i) {
	this.points.splice(i, 1);
};

SVGPathManager.prototype.removeFirst = function () {
	this.points.splice(0, 1);
	this.direction = 1;
};

SVGPathManager.prototype.removeLast = function () {
	this.points.pop();
	this.direction = -1
};

SVGPathManager.prototype.restart = function () {
	this.points = [];
	if (this.startPoint) this.points.push(this.startPoint);
	this.direction = 0;
};
SVGPathManager.prototype.draw = function () {
	SVGManager.drawPath(this.svg, this);
};

SVGPathManager.prototype.redraw = function () {
	SVGManager.redrawPath(this.svg, this);
};

SVGPathManager.prototype.remove = function () {
	SVGManager.removeElement(this.svg, this.id);
};

SVGPathManager.prototype.close = function () {
	this.append({
		calculated: true,
		type: "Z"
	});
};

SVGPathManager.prototype.calculatePathWidth = function (obs_2) {
	var tmpArray = [];
	for (var i = this.size() - 1; i >= 0; --i) {
		var point = this.getElement(i);
		var closest = point.closest;
		if (closest) {
			var xDist = (point.x - closest.x) * obs_2;
			var yDist = (point.y - closest.y) * obs_2;
			tmpArray.push({
				x: point.x - xDist,
				y: point.y - yDist
			});
			delete point.closest
		}
	}

	var lastPoint;
	for (var j = 0; j < tmpArray.length; ++j) {
		var nextPoint = tmpArray[j];
		if (lastPoint) {
			// this script provides rouded corners
			if (j == 1) {
				nextPoint.type = "Q";
				nextPoint.x1 = lastPoint.x;
				nextPoint.y1 = lastPoint.y;
			} else if (j == tmpArray.length - 1) {
				nextPoint.type = "Q";
				nextPoint.x1 = nextPoint.x;
				nextPoint.y1 = nextPoint.y;
				nextPoint.x = this.getElement(0).x;
				nextPoint.y = this.getElement(0).y;
			} else {
				nextPoint.type = "L";
			}
			nextPoint.calculated = true;
			this.append(nextPoint);
		}
		lastPoint = nextPoint;
	}
	this.close();
};

SVGPathManager.prototype.deleteCalculated = function () {
	for (var i = this.size() - 1; i >= 0; --i) {
		if (this.getElement(i).calculated) {
			this.removeElement(i);
		}
	}
};

SVGPathManager.matchPoints = function (array1, array2) {
	array1.points.forEach(function (point) {
		var closest = HeartSVG.getClosestPoint(point, array2);
		if (!closest.closest) {
			closest.closest = point;
		} else {
			var e1 = SVGManager.euclideanDistance(point, closest);
			var e2 = SVGManager.euclideanDistance(point, point.closest);
			if (e2 < e1) {
				closest.closest = point;
			}
		}
		point.closest = closest;
	});
};
SVGPathManager.prototype.getStartPoint = function (list, segment, point) {
	var initPoints = SVGPathManager.filterByString(list, "init");
	var initPointsInSegment = SVGPathManager.containsNumber(initPoints, segment[0]);
	var closest = this.getClosestFiltered(initPointsInSegment, point);
	var type = closest.getAttribute("id").substring(closest.getAttribute("id").length - 1);
	var validSegment = SVGPathManager.filterArrayString(segment, type);
	for (var obj in validSegment) {
		if (validSegment[obj] == closest.getAttribute('id').substring(4, closest.getAttribute("id").length)) {
			var validSegmentAux = validSegment[obj];
			break;
		}
	}
	var finalPoint = {
		segment: closest.getAttribute("id"),
		percent: SVGPathManager.getPercent(point, closest, SVGPathManager.filterByString(list, "end" + validSegmentAux)[0]),
		type: closest.getAttribute("id").substring(closest.getAttribute("id").length - 1)
	};
	return finalPoint;
};
SVGPathManager.prototype.getEndPoint = function (list, segment, point, type) {
	var endPoints = SVGPathManager.filterByString(list, "end");
	var endPointsInSegment = [];
	for (let i = 0; i < segment.length; i++) {
		if (segment[i].substring(segment[i].length - 1) == type) {
			var segmentContain = SVGPathManager.containsNumber(endPoints, segment[i]);
			endPointsInSegment.push(SVGPathManager.filterByString(segmentContain, type)[0]);
		}
	}
	var closest = this.getClosestFiltered(endPointsInSegment, point);
	var validSegment = SVGPathManager.filterArrayString(segment, type);
	for (var obj in validSegment) {
		if (validSegment[obj] == closest.getAttribute('id').substring(3, closest.getAttribute("id").length)) {
			var validSegmentAux = validSegment[obj];
			break;
		}
	}
	var finalPoint = {
		segment: closest.getAttribute("id"),
		percent: (100 - SVGPathManager.getPercent( point, SVGPathManager.filterByString(list, "init" + validSegmentAux)[0], closest)).toFixed(2).toString(),
		type: type
	};
	return finalPoint;
};

SVGPathManager.filterArrayString = function (array, string) {
	return array.filter(function (str) {
		switch (browser) {
			case "chrome":
			case "opera":
				return str.includes(string);
				break;
			case "firefox":
			case "safari":
			case "explorer":
			case "edge":
				return str.indexOf(string) != -1;
				break;
		}
	});
};


SVGPathManager.parseSegment = function (path, svgPath) {
	var segments = svgPath.getPathObj(path);
	var points = [];
	var lastPoint = {};
	for (var j = 0, len = segments.length; j < len; ++j) {
		var pathSeg = segments[j];
		var values = pathSeg.values;
		var point = null;
		switch (pathSeg.type.toUpperCase()) {
			case "M":
				point = { x: values[0], y: values[1] };
				break;
			case "L":
				point = { x: values[0], y: values[1] };
				break;
			case "C":
				point = { x1: values[0], y1: values[1], x2: values[2], y2: values[3], x: values[4], y: values[5] };
				break;
			case "A":
				point = { rx: values[0], ry: values[1], x_axis_rotation: values[2], large_arc_flag: values[3], sweep_flag: values[4], x: values[5], y: values[6] };
				break;
			case "S":
				point = { x2: values[0], y2: values[1], x: values[2], y: values[3] };
				break;
			case "Q":
				point = { x1: values[0], y1: values[1], x: values[2], y: values[3] };
				break;
			case "T":
				point = { x: values[0], y: values[1] };
				break;
			case "Z":
				point = { x: values[0], y: values[1] };
				break;
			case "H":
				point = { x: values[0], y: lastPoint.y };
				break;
			case "V":
				point = { x: lastPoint.x, y: values[0] };
				break;
			default: break;
		}
		point.type = pathSeg.type;
		point.idx = j;
		points.push(point);
		lastPoint = point;
	}
	return points
};
SVGPathManager.prototype.getFinalPoint = function () {
	if (this.direction == -1) {
		return this.points[0];
	} else {
		for (var i = this.size() - 1; i >= 0; --i) {
			if (!this.getElement(i).calculated) {
				return this.getElement(i);
			}
		}
	}

}
/**
 * segments: puntos del segmento
 * point: punto a buscar dentro de los círculos del segmento
 */
SVGPathManager.prototype.getClosestSegment = function (segments, point) {

	var closestPositiveDistance = -Infinity;
	var closestNegativeDistance = +Infinity;

	// De los puntos del segmento, buscamos los círculos más cercanos
	var distances = [];
	var closestNegativeValue = -Infinity;
	var closestPositiveValue = +Infinity;
	segments.forEach(function (segment) {
		// calculamos el diferencial del índice. Recordad que los índices de los puntos
		// se han calculado en función de los más cercanos.

		// por ello, buscamos el valor mínimo, máximo y obtenemos un array de distancias
		var pointDistance = segment.idx - point.idx;
		// si el diferencial es positivo, vamos en un sentido, si no, en el otro
		// es importante contemplar el 0, es decir, que terminamos sobre el punto

		distances.push({ dist: pointDistance, circle: segment });
		if (pointDistance >= 0) {
			if (pointDistance < closestPositiveValue) {
				closestPositiveValue = pointDistance;
			}
		}
		if (pointDistance <= 0) {
			if (pointDistance > closestNegativeValue) {
				closestNegativeValue = pointDistance;
			}
		}
	});
	// Ahora buscamos los posibles match sabiendo los valores más cercanos, tanto por arriba como por abajo
	var closestPoints = [];
	distances.forEach(function (distance) {
		if (distance.dist == closestNegativeValue || distance.dist == closestPositiveValue) {
			closestPoints.push(distance.circle);
		}
	});
	return this.getSegmentInBetween(closestPoints);
}

SVGPathManager.prototype.getSegmentInBetween = function (arr) {
	arr = arr.reduce((previousValue, currentValue) => {const id = currentValue.id; 
		const found = arr.find(val => {
			if(id.includes("init")){ return val.id.includes("endS" + id.replace( /^\D+/g, ''))}
			else {return val.id.includes("initS" + id.replace( /^\D+/g, '')) }
		}
		)
		if(found) previousValue.push(currentValue);
		return previousValue;
	}, []);
	console.log(arr)
	var keys = [];
	arr.forEach(function (key) {
		keys.push(key.id.replace("init", "").replace("end", ""));
	});
	var segment = '';
	for (var i = 0; i < keys.length - 1; ++i) {
		var current = keys[i];
		var remaining = keys.slice(i + 1, keys.length);
		if (remaining.indexOf(current) != -1) {
			segment = current;
			break;
		}
	}
	return segment;
}

SVGPathManager.prototype.getClosestFiltered = function (segments, point) {
	var closest = null;
	var closestDistance = Infinity;
	segments.forEach(function (segment) {
		var pointDistance = Math.abs(segment.idx - point.idx);
		if (pointDistance < closestDistance) {
			closestDistance = pointDistance;
			closest = segment;
		}
	});
	return closest;
};

/**
 * Este método extrae todos los segmentos que hay el el path cerrado en cuestión
 * list: listado de segmentos
 * points: el path entero
 */
SVGPathManager.prototype.getSegmentsOnThisPath = function (list, points) {
	var segments = [];
	var pointsInSegment = SVGPathManager.parseSegment(points, this);
	for (var j = 0; j <= pointsInSegment.length - 1; ++j) {
		if (!pointsInSegment[j] || pointsInSegment[j].x === null || pointsInSegment[j].x === undefined || pointsInSegment[j].y === null || pointsInSegment[j].y === undefined) {
			continue;
		}
		for (var i = list.length - 1; i >= 0; --i) {
			var x = 0, y = 0;
			switch (browser) {
				case "chrome":
				case "opera":
					x = parseFloat(list[i].getAttribute("cx"));
					y = parseFloat(list[i].getAttribute("cy"));
					break;
				case "firefox":
				case "safari":
				case "explorer":
				case "edge":
					x = list[i].cx.baseVal.value;
					y = list[i].cy.baseVal.value;
					break;
			}
			if (Math.abs(pointsInSegment[j].x - x) < this.proximity_threshold && Math.abs(pointsInSegment[j].y - y) < this.proximity_threshold) {
				if(!segments.find(value => value.id === list[i].id)){ 
					list[i].idx = pointsInSegment[j].idx;
					segments.push(list[i]);}
			}

		}
	}
	return segments;
};
SVGPathManager.prototype.getSegment = function (list, points, startA, startB, endA, endB) {
	var segmentBreakPoints = this.getSegmentsOnThisPath(list, points);
	var closestInitA = this.getClosestSegment(segmentBreakPoints, startA);
	var closestInitB = this.getClosestSegment(segmentBreakPoints, startB);
	var closestEndA = this.getClosestSegment(segmentBreakPoints, endA);
	var closestEndB = this.getClosestSegment(segmentBreakPoints, endB);

	var allSegments = [closestInitA, closestInitB, closestEndA, closestEndB];
	console.log(allSegments)
	allSegments = [...new Set(allSegments)];
	var obj = {
		segments: allSegments,
		segmentBreakPoints: segmentBreakPoints
	};
	return obj;
};

SVGPathManager.getPercent = function (point, init, end) {
	var distanceTotal;
	var distancePoint;
	if (end.idx > init.idx) {
		distanceTotal = end.idx - init.idx + 1;
		distancePoint = point.idx - init.idx + 1;
	} else {
		distanceTotal = end.idx - init.idx - 1;
		distancePoint = point.idx - init.idx - 1;
	}
	var percent = ((distancePoint / distanceTotal) * 100).toFixed(2);
	return percent;
};
SVGPathManager.filterByString = function (array, string) {
	return array.filter(function (obj) {
		switch (browser) {
			case "chrome":
			case "opera":
				return obj.id.includes(string);
				break;
			case "firefox":
			case "safari":
			case "explorer":
			case "edge":
				return obj.id.indexOf(string) != -1;
				break;
		}
	});
};
SVGPathManager.containsNumber = function (array, string) {
	return array.filter(function (obj) {
		return obj.id.match(/\d+/) == string.match(/\d+/)[0];
	});
};
SVGPathManager.prototype.findPointByCordinates = function (x, y) {
	if (Array.prototype.find) {
		return this.points.find(function (obj) {
			return obj.x == x && obj.y == y;
		});
	} else {
		for (var i = 0; i <= this.points.length - 1; i++) {
			if (this.points[i].x == x && this.points[i].y == y) {
				return this.points[i];
			}
		}
	}
}
SVGPathManager.toJSON = function (path) {
	console.log(path)
	var seglist = path.pathSegList;
	console.log(seglist);
	var length = seglist.numberOfItems;
	var seg = null;
	out = [], data = null, type = null;



	for (i = 0; i < length; i++) {
		seg = seglist.getItem(i);
		type = seg.pathSegTypeAsLetter;
		data = {
			type: seg.pathSegTypeAsLetter,
			values: []
		};

		switch (type) {
			case 'M':
			case 'L':
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;

			case 'C':
				data.values.push(seg.x1);
				data.values.push(seg.y1);
				data.values.push(seg.x2);
				data.values.push(seg.y2);
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;
			case 'A':
				data.values.push(seg.rx);
				data.values.push(seg.ry);
				data.values.push(seg.x_axis_rotation);
				data.values.push(seg.large_arc_flag);
				data.values.push(seg.sweep_flag);
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;
			case 'S':
				data.values.push(seg.x2);
				data.values.push(seg.y2);
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;
			case 'Q':
				data.values.push(seg.x1);
				data.values.push(seg.y1);
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;
			case 'T':
			case 'Z':
				data.values.push(seg.x);
				data.values.push(seg.y);
				break;
			/*
			 * to
			 * be
			 * continued
			 */
		}
		out.push(data);
	}

	return out;
};
SVGPathManager.prototype.getPathObj = function (path) {
	var segments;
	switch (browser) {
		case "chrome":
		case "opera":
			segments = path.getPathData();
			break;
		case "firefox":
		case "safari":
		case "explorer":
		case "edge":
			segments = SVGPathManager.toJSON(path);
			break;
	}
	return segments;

}