var SVGManager = function(){};

SVGManager.euclideanDistance = function(p1, p2){
    var distance = Infinity;
    if(p1 && p2){
        distance = Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2)
    }
    return distance
};

SVGManager.removeElement = function(svg, id){
    var element = svg.getElementById(id);
    if(element != null){
        element.parentNode.removeChild(element);
    }
};

SVGManager.redrawCircle = function(svg, id, x, y, r, color, width){
    SVGManager.removeElement(svg, id);
    SVGManager.drawCircle(svg, id, x, y, r, color, width);
};

SVGManager.drawCircle = function(svg, id, x, y, r, color, width){
    var circle = document.createElementNS("http://www.w3.org/2000/svg", 'circle');
    circle.setAttribute("cx", x);
    circle.setAttribute("cy", y);
    circle.setAttribute("r", r);
    circle.setAttribute("id", id);
    circle.style.fill = color;
    circle.style.stroke = color;
    circle.style.strokeWidth = width + "px";
    svg.viewportG.appendChild(circle);
};

SVGManager.drawLine = function(svg, id, x1, y1, x2, y2, color, width){
    var line = document.createElementNS("http://www.w3.org/2000/svg", 'line');
    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttribute("id", id);
    line.style.stroke = color;
    line.style.strokeWidth = width + "px";
    svg.viewportG.appendChild(line);
};

SVGManager.redrawPath = function(svg, path){
    SVGManager.removeElement(svg, path.id);
    SVGManager.drawPath(svg, path);
};

SVGManager.drawPath = function(svg, path){
    var svgPath = document.createElementNS("http://www.w3.org/2000/svg", 'path');
    svgPath.setAttribute("d", path.toString());
    svgPath.setAttribute("id", path.id);
    svgPath.style.fill = path.fillColor;
    svgPath.style.stroke = path.strokeColor;
    svgPath.style.strokeWidth = path.strokeWidth + "px";
    svg.viewportG.appendChild(svgPath);
};

SVGManager.positionById = function(prefix,pathId){
    return pathId.substring(prefix.length);
};